import React from 'react'
import { Link, graphql } from 'gatsby'

import DefaultLayout from '../components/Layout/default'
import SEO from '../components/SEO'
import CardGrid from '../components/Article/CardGrid'
import Tags from '../components/Tags'

// type
import { SitePageContext, TagPageQuery } from '../../types/graphql-types'

// interface
interface TagRouteProps {
  data: TagPageQuery
  pageContext: SitePageContext
}

const TagRoute: React.FC<TagRouteProps> = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges
  const { tag } = pageContext
  const { totalCount } = data.allMarkdownRemark
  const tagHeader = `記事数:${totalCount}`

  return (
    <DefaultLayout title={`タグ:${tag}`}>
      <SEO
        title={`タグ:${tag}`}
        slug={encodeURI(`/tags/${tag}`)}
        keywords={[
          '経済',
          'ブログ',
          'Notion',
          '投資',
          'プログラミング',
          'デザイン',
        ]}
      />

      <div className="container mx-auto">
        <div className="pt-10 sm:py-10 flex flex-col sm:flex-row mx-5">
          <div className="flex flex-col w-full sm:w-2/3 sm:ml-5">
            <h1 className="text-2xl px-2 mb-5">{tagHeader}</h1>

            <CardGrid articleEdges={posts}>
              <div className="text-center m-5">
                <Link
                  to="/tags/"
                  className="block m-3 md:inline-block md:mt-0 text-gray-800 hover:text-linkColor hover:underline mr-4"
                >
                  すべてのタグを見る
                </Link>
              </div>
            </CardGrid>
          </div>

          <div className="flex flex-col w-full sm:w-1/3 mt-10 sm:pl-10">
            <h2 className="text-2xl">すべてのタグ</h2>

            <Tags />
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    tags: allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            tags
            templateKey
            date(formatString: "YYYY/MM/DD")
            featuredpost
            featuredimage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`
